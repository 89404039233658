import React, { useState, useEffect } from 'react';
import useGlobal from '../../store';
import { formatArticleTitle, isFinalSale } from '../../utils/formatters';
import ProductImg from '../UI/ProductImg';

const ReturnItemsItem = ({ selectedItem }) => {
  const [globalState, globalActions] = useGlobal();
  const { lang, text, selectedItems, reasons, channels, orders } = globalState;
  const reasonSelect = React.createRef();
  const [condQuestion, displayCondQuestion] = useState(false);
  const [refundExceptionCheck, setRefundExceptionCheck] = useState(false);
  const [isAltidollarRefundCampaign, setIsAltidollarRefundCampaign] = useState(selectedItem.altidollar_refund);
  const [altidollarRefundCheck, setAltidollarRefundCheck] = useState(isAltidollarRefundCampaign);

  const orderItems = orders[0].line_items.find(el => el.sku === selectedItem.sku);
  const productTags = orderItems.tags ? orderItems.tags.split(', ') : '';

  function handleSelect(e) {
    e.preventDefault();
    let reasonId;

    if (e.target.value === '10') {
      reasonId = parseInt(e.target.value);
    } else if (e.target.value === 'null') {
      reasonId = null;
    } else {
      reasonId = parseInt(e.target.value);
    }

    const reason = {
      _id: reasonId,
      cond_question: null
    };

    globalActions.items.updateItem(selectedItem, 'reason', reason);

    const selectedReason = reasons.find(reason => reason._id === reasonId);
    let condQuestion = '';
    if (selectedReason) {
      condQuestion = lang === 'fr' ? selectedReason.cond_question_fr : selectedReason.cond_question_en;
    }
    condQuestion && displayCondQuestion(condQuestion);
  }

  function submitCondQuestion(e) {
    e.preventDefault();
    const value = e.target.otherReason.value;
    const itemIndex = selectedItems.findIndex(el => el.uniq_id === selectedItem.uniq_id);
    const reason = { ...selectedItems[itemIndex].reason, cond_question: value };

    globalActions.items.updateItem(selectedItem, 'reason', reason);

    document.querySelector('[name="otherReason"]').value = '';
    displayCondQuestion(false);
  }

  function toggleAltidollarsRefund(e) {
    e.preventDefault();

    if (isAltidollarRefundCampaign) {
      setIsAltidollarRefundCampaign(isAltidollarRefundCampaign);
    } else if (!isAltidollarRefundCampaign) {
      setIsAltidollarRefundCampaign(false);
    }

    const item = selectedItems.find(el => el.uniq_id === selectedItem.uniq_id);
    setAltidollarRefundCheck(!altidollarRefundCheck);
    const current = item.altidollar_refund;
    globalActions.items.updateItem(selectedItem, 'altidollar_refund', !current);
  }

  function toggleRefundException(e) {
    e.preventDefault();
    const itemIndex = selectedItems.findIndex(el => el.uniq_id === selectedItem.uniq_id);
    const current = selectedItems[itemIndex].refund_exception;

    globalActions.items.updateItem(selectedItem, 'refund_exception', !current);
  }

  useEffect(() => {
    const item = selectedItems.find(el => el.line_item_id === selectedItem.line_item_id);
    setRefundExceptionCheck(item.refund_exception);
  }, [selectedItems, selectedItem.line_item_id]);

  return (
    <div className="return-form__returned-items__item message">
      <div className={`message-header ${isFinalSale(productTags, 'return-creating') ? 'has-background-orange' : ''}`}>
        {formatArticleTitle(lang, selectedItem)} - {selectedItem.sku}
        <button
          onClick={() => globalActions.items.removeSelectedItem(selectedItem)}
          className="delete"
          aria-label="delete"
        ></button>
      </div>
      <div className="message-body is-flex centered">
        <ProductImg item={selectedItem} size="97px" margin="0.1em 0.5em 0.1em 0" padding="3px" />

        <div className="return-form__returned-items__item-right">
          {isFinalSale(productTags, 'return-creating') && (
            <p className="label has-text-orange">{text.return_creation.final_sale}</p>
          )}

          <div className="return-form__returned-items__item-element is-flex aligned wrap">
            <span className="has-text-weight-bold">{text.return_creation.order}:</span>
            <span>
              {selectedItem.channel}-{selectedItem.order_name}
            </span>
          </div>
          <div className="return-form__returned-items__item-element is-flex aligned wrap">
            <span className="has-text-weight-bold">{text.return_creation.return_reason}:</span>
            <div className="select">
              <select ref={reasonSelect} onChange={handleSelect}>
                <option value="null">-- {text.return_creation.please_choose} --</option>
                {reasons.map(reason => (
                  <option key={reason._id} value={reason._id} selected={Number(selectedItem?.reason?._id) === reason._id}>
                    {lang === 'fr' ? reason.title_fr : reason.title}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="return-form__returned-items__item-element">
            {channels[selectedItem.channel].store_credits_policy && (
              <>
                <span className="label">Exceptions:</span>
                <label className="checkbox-container" onClick={toggleAltidollarsRefund}>
                  <input name="altidollar-exception" type="checkbox" readOnly checked={altidollarRefundCheck} />
                  <span className="checkmark" />
                  {text.return_creation.altidollar_refund}
                  {isAltidollarRefundCampaign && (
                    <span className="is-size-7 has-text-weight-bold">
                      {' '}
                      (***{text.return_creation.altidollar_refund_campaign})
                    </span>
                  )}
                </label>
              </>
            )}
          </div>
        </div>

        <div className={`modal ${condQuestion ? 'is-active' : ''}`}>
          <div className="modal-background"></div>
          <div className="modal-content">
            <div className="box">
              <label className="label">{condQuestion}</label>
              <form onSubmit={submitCondQuestion}>
                <div className="field has-addons">
                  <p className="control is-expanded">
                    <input
                      className="input"
                      name="otherReason"
                      placeholder={text.return_creation.cond_question_placeholder}
                    />
                  </p>
                  <p className="control">
                    <button className="button is-link">{text.return_creation.submit}</button>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnItemsItem;
